@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value lg, md, sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value black from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .container {
    display: flex;
    flex-direction: column;
    margin-bottom: lg;
    padding: 0 sm;
    text-align: left;
    width: 50%;

    img {
      border-radius: borderRadiusMedium;
    }

    @media breakpointMdUp {
      width: 25%;
      margin-bottom: 0;
    }
  }

  .textWrapper {
    flex-grow: 1;
    margin: sm 0;
  }

  .categoryBrandText {
    font-size: 12px;
    margin-bottom: sm;
  }

  .purchasedText {
    color: black;
  }
}
