@value sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value midGrey, lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    max-width: 500px;
    text-align: center;
    margin: lg auto 60px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px 1px lightGrey2;
    padding: lg;
  }

  .container-repop {
    max-width: 800px;
    box-shadow: none;
    padding: 0;

    @media breakpointMdUp {
      box-shadow: 0 0 10px 1px lightGrey2;
      padding: lg;
    }
  }

  .iconText {
    margin-top: md;
    color: midGrey;
  }

  .ctaButtonWrapper {
    margin: md 0 0;

    a {
      text-decoration: none;
    }
  }

  .title {
    margin: xl 0 sm 0;
  }

  .description {
    color: midGrey;
    margin-bottom: md;
  }

  .receiptsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
